import dynamic from 'next/dynamic';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const Crisp = dynamic(() => import('./CrispBrowser'), {
    ssr: false,
});

export const CrispShouldLoadAfterEditor = dynamic(
    async () => {
        await Promise.race([
            new Promise((resolve) => {
                window.addEventListener('firstSceneIsPrepared', resolve);
            }),
            sleep(10000),
        ]);

        return await import('./CrispBrowser');
    },
    {
        ssr: false,
    },
);
