import { getPublicEnv } from '../env/env';
import { fetchUnAuthorizationApi } from '../fetch/fetch';
import { getGenericArray, getGenericObject, isObjectWithKey } from '../validateData';

interface StaticPagesResponseRaw {
    id: number;
    code: string;
    translations: {
        [languageCode: string]: {
            slug: string;
            content: string;
            metaDescription: string;
            metaKeywords: string;
            name: string;
            image: null | {
                path: string;
            };
        };
    };
}

export interface StaticPageSimple {
    slug: {
        [language: string]: string;
    };
    name: {
        [language: string]: string;
    };
}
export interface StaticPagesResponseData {
    content: string;
    metaDescription: string;
    metaKeywords: string;
    image: null | string;
    slug: string;
    name: string;
}

export interface StaticPagesResponse {
    id: number;
    code: string;
    translations: {
        [languageCode: string]: StaticPagesResponseData;
    };
}

const isStaticPagesResponseData = (data: unknown): data is StaticPagesResponseData =>
    isObjectWithKey(data) &&
    typeof data.slug === 'string' &&
    typeof data.content === 'string' &&
    typeof data.metaDescription === 'string' &&
    typeof data.metaKeywords === 'string' &&
    typeof data.name === 'string' &&
    (data.image === null || (isObjectWithKey(data.image) && typeof data.image.path === 'string'));

const isStaticPagesResponseRaw = (data: unknown): data is StaticPagesResponseRaw =>
    isObjectWithKey(data) &&
    typeof data.id === 'number' &&
    typeof data.code === 'string' &&
    isObjectWithKey(data.translations) &&
    Object.keys(getGenericObject(data.translations, isStaticPagesResponseData)).length > 0;

let cache: {
    data: StaticPagesResponse[];
    timestamp: number;
} | null = null;

const CACHE_TIME = 10 * 60 * 1000; // 10 min

export const fetchStaticPages = async (): Promise<StaticPagesResponse[]> => {
    if (cache && Date.now() - cache.timestamp < CACHE_TIME) {
        return cache.data;
    }

    const apiUrl = await getPublicEnv('NEXT_PUBLIC_API_URL');
    const mediaDomainUrl = await getPublicEnv('MEDIA_DOMAIN_URL');

    const pages = await fetchUnAuthorizationApi(`${apiUrl}/shop/cms-plugin/pages`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (Array.isArray(pages)) {
        const result = getGenericArray(pages, isStaticPagesResponseRaw).map(
            (page): StaticPagesResponse => ({
                ...page,
                translations: Object.entries(page.translations).reduce<{
                    [languageCode: string]: StaticPagesResponseData;
                }>((acc, [languageCode, translation]) => {
                    acc[languageCode] = {
                        ...translation,
                        image: translation.image ? `${mediaDomainUrl}${translation.image.path}` : null,
                    };

                    return acc;
                }, {}),
            }),
        );

        cache = {
            data: result,
            timestamp: Date.now(),
        };

        return result;
    }

    return [];
};
