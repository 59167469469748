import { StaticPageSimple, StaticPagesResponse } from '../../services/staticPages/staticPages';

export const formatStaticPagesToBaseData = (pages: StaticPagesResponse[]): StaticPageSimple[] =>
    pages.map(({ translations }) =>
        Object.entries(translations).reduce<StaticPageSimple>(
            (acc, [lang, item]) => {
                acc.name[lang] = item.name;
                acc.slug[lang] = item.slug;

                return acc;
            },

            {
                name: {},
                slug: {},
            },
        ),
    );
