import styled from 'styled-components';
import Breakpoint from '../../constants/Breakpoint.enum';
import { TextM } from '@formify/frontend-components';

export const StaticPageFooter = styled.footer`
    padding: 100px 20px;
    background-color: #f6f6f6;

    @media (min-width: ${Breakpoint.Mobile}) {
        padding: 100px 30px;
    }
`;

export const StaticPageFooterGrid = styled.div`
    display: grid;
    max-width: 1240px;
    margin: 0 auto;
    gap: 10px 50px;
    grid-template-areas:
        'newsletter'
        'contact'
        'follow'
        'copyright'
        'terms';
    grid-template-columns: 1fr;

    @media (min-width: ${Breakpoint.Mobile}) {
        grid-template-areas:
            'newsletter newsletter'
            'contact follow'
            'copyright copyright'
            'terms terms';
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${Breakpoint.Tablet}) {
        grid-template-areas:
            'newsletter contact follow'
            'copyright terms terms';

        grid-template-columns: 2fr 1fr auto;
    }
`;

export const FooterHeader = styled(TextM)`
    padding-bottom: 22px;
`;

export const FooterNewsletterArea = styled.div`
    grid-area: newsletter;
    padding-bottom: 40px;
`;

export const FooterContactArea = styled.div`
    grid-area: contact;
    padding-bottom: 40px;
`;

export const FooterFollowArea = styled.div`
    grid-area: follow;
    padding-bottom: 40px;
`;

export const FooterCopyrightArea = styled.div`
    grid-area: copyright;
`;

export const FooterTermsArea = styled.div`
    grid-area: terms;
`;
