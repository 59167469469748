import styled from 'styled-components';
import Breakpoint from '../../constants/Breakpoint.enum';

export const StaticPageImage = styled.div`
    height: 21.875vw;
    min-height: 200px;
    background-color: #e5e5e5;
    position: relative;
    overflow: hidden;
`;

interface StaticPageImageOverlayProps {
    bottom?: string;
}

export const StaticPageImageOverlay = styled.div<StaticPageImageOverlayProps>`
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.19) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    bottom: ${(props: StaticPageImageOverlayProps) => props.bottom || '0'};
    right: 0;
    left: 0;
`;

export const StaticPageMain = styled.main`
    width: 100%;
    max-width: 768px;
    padding: 40px 20px 100px 20px;
    margin: 0 auto;

    @media (min-width: ${Breakpoint.Mobile}) {
        padding: 80px 30px 160px 30px;
    }
`;

export const HTMLContentStyled = styled.div<{ gap: number }>`
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    padding-top: ${({ gap }) => `${gap}px`};

    h2 {
        font-size: 20px;
        line-height: 26px;
        padding-top: 60px;
        font-weight: 500;
    }

    p + p {
        padding-top: ${({ gap }) => `${gap}px`};
    }
`;
