import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCookieVisibility } from '../../store/layout/actions';
import { fetchOrderData } from '../../store/project/actions';
import { Loader } from '../../components/atoms/Loader/Loader';
import { fetchStaticPagesForMenuAction } from '../decoratorFetchStaticPagesForMenu';

export const AllDecorators: React.FC<{ children: () => React.ReactNode }> = ({ children }) => {
    const router = useRouter();
    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(fetchOrderData());

        fetchStaticPagesForMenuAction().then((setPagesAction) => {
            dispatch(setPagesAction);
        });

        const cookiesConsent = getCookie('cookies_consent');

        if (cookiesConsent !== true) {
            dispatch(setCookieVisibility(true));
        }
    }, [dispatch]);

    if (router.isFallback) {
        return <Loader isPending={true}></Loader>;
    }

    return <>{children()}</>;
};
